<template>
  <div class="container">
    <div class="space-top-4 space-top-md-5 space-top-lg-4 space-bottom-2">
      <div class="row align-items-lg-center">
        <div class="col-lg-5 mb-7 mb-lg-0">
          <!-- Info -->
          <h1 class="mb-4"><span class="text-primary font-weight-bold">GMB Category Explorer</span></h1>
          <p>
            Stay on top of GMB category changes. We refresh our data daily and provide a digest of changes on a weekly (Monday) basis.
            Opt-in below to get a weekly email on what GMB categories have been added and which have been removed.
          </p>

          <p>
            Just select the country you want to see GMB categories for. You can filter, sort, and find the best categories for your listing with this tool.
          </p>
          <!-- End Info -->
        </div>

        <div class="col-lg-7">
          <!-- SVG Icon -->
          <img class="embed-responsive" :src="require('post-scheduling-new.svg')">
          <!-- End SVG Icon -->
        </div>
      </div>
    </div>

    <div class="space-top-2 space-top-md-3 space-bottom-2 space-bottom-md-3">
      <div class="row align-items-lg-center">
        <div class="col-lg-6 mb-7 mb-lg-0">
          <img class="embed-responsive" :src="require('gmb-management-hero.png')">
        </div>

        <div class="offset-lg-1 col-lg-5">
          <h2 class="text-primary font-weight-normal">
            Sign Up For Updates
          </h2>
          <p>Enter your email to sign up for updates of categories.</p>

          <form action="/" class="w-100" @submit.prevent="onSubscribeSubmit">
            <select v-model="countryCodeForm" class="form-control mb-4">
              <option v-for="cc in countries" :value="cc.code" :key="cc.code">{{ cc.name }}</option>
            </select>
            <div class="mb-4">
              <label class="sr-only" for="subscriber-email">Email</label>
              <input
                v-model="email"
                type="email"
                class="form-control"
                id="subscriber-email"
                placeholder="Email">
              <small v-if="emailError" class="text-danger d-block">
                {{ emailError }}
              </small>
              <small>
                We'll never spam you or sell your data.
              </small>
            </div>
            <button type="submit" class="btn btn-primary mr-auto">Sign Up For Updates</button>
          </form>
        </div>
      </div>
    </div>

    <div v-if="historyIsFetching" class="card my-5 text-center pt-3 pb-2">
      <h6>
        <i class="far fa-spinner fa-spin fa-fw" /> Fetching changes for the last month.
      </h6>
    </div>

    <template v-else-if="history.new.length || history.lost.length">
      <p class="mt-5">
        Google has added {{ history.new.length }} categor{{ history.new.length === 1 ? 'y' : 'ies' }}
        in the last 30 days and removed {{ history.lost.length }}.
      </p>

      <div class="card mb-5 p-3">
        <div class="row">
          <div class="col-md-6">
            <h5 class="text-center">New</h5>
            <ol>
              <li v-for="{ id, name } in history.new" :key="id">{{ name }}</li>
            </ol>
          </div>
          <div class="col-md-6">
            <h5 class="text-center">Lost</h5>
            <ol>
              <li v-for="{ id, name } in history.lost" :key="id">{{ name }}</li>
            </ol>
          </div>
        </div>
      </div>
    </template>

    <div v-else class="card my-5 text-center pt-3 pb-2">
      <h6>Nothing changed for the last month.</h6>
    </div>

    <div class="row align-items-center mb-3 mt-5">
      <div class="col-auto">
        <select v-model="countryCode" class="form-control">
          <option v-for="cc in countries" :value="cc.code" :key="cc.code">{{ cc.name }}</option>
        </select>
      </div>

      <div class="col-auto flex-grow-1">
        <input v-model="search" type="search" class="form-control" placeholder="Search">
      </div>

      <div class="col-auto">
        <select v-model="perPage" class="form-control" width>
          <option v-for="pp in perPageOptions" :value="pp" :key="pp">{{ pp }}</option>
        </select>
      </div>
    </div>
    <VueSlimTable
      ref="table"
      :source="tableSource"
      :columns="columns"
      class="table table-striped table-light gmb-categories-table"
      :per-page="perPage" />
  </div>
</template>

<script>
import snakeCase from 'to-snake-case'
import axios from 'axios'
import VueSlimTable from 'vue-slim-tables/src/js'

import { DEFAULT_ERROR_MESSAGE } from 'common/constants'
import axiosTransform from 'common/axios'

// TODO: we should create axios instance and use it everywhere
const csrf = document.querySelector('meta[name="csrf-token"]')
axios.defaults.headers.common['X-CSRF-Token'] = csrf.getAttribute('content')

export default {
  components: { VueSlimTable },
  props: {
    countries: { type: Array, required: true }
  },
  data() {
    return {
      perPage: 25,
      search: '',
      countryCode: 'US',
      countryCodeForm: 'US',
      email: '',
      emailError: null,
      history: {
        new: [],
        lost: []
      },
      historyIsFetching: true
    }
  },
  methods: {
    tableSource(obj) {
      const params = { ...obj, order: obj.order?.map((o) => ({ ...o, field: snakeCase(o.field) })) }
      if (this.search.length) params.search = this.search.trim()
      if (this.countryCode) params.country_code = this.countryCode

      return axios.get(
        '/api/gmb_categories',
        {
          params,
          paramsSerializer(json) { return qs.stringify(json, { arrayFormat: 'brackets' }) }
        }
      ).then((res) => res.data)
    },
    onSubscribeSubmit() {
      this.emailError = null
      const gmbCategorySubscriber = {
        email: this.email,
        countryCode: this.countryCodeForm
      }

      axios.post(
        '/api/gmb_category_subscribers',
        { gmbCategorySubscriber },
        axiosTransform
      ).then(() => {
        this.email = ''
      }).catch((err) => {
        this.emailError = err.response?.data?.text?.email?.join(', ') || DEFAULT_ERROR_MESSAGE
      })
    },
    fetchHistory() {
      this.historyIsFetching = true
      this.history = {
        new: [],
        lost: []
      }

      axios.get('/api/gmb_categories/history', { params: { country_code: this.countryCode } })
        .then((res) => {
          this.history = res.data
        })
        .finally(() => {
          this.historyIsFetching = false
        })
    }
  },
  watch: {
    search() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 300)
    },
    countryCode() {
      this.$refs.table.refetch()
      this.fetchHistory()
    }
  },
  created() {
    this.columns = [
      { title: 'Category Name', key: 'name', orderable: true },
      { title: 'GCID', key: 'remoteId' },
      { title: 'First Seen', key: 'createdAt', orderable: true },
      { title: 'Last Seen', key: 'deletedAt', orderable: true }
    ]

    this.perPageOptions = [25, 50, 100]

    this.fetchHistory()
  }
}
</script>
