import Cookies from 'js-cookie'

Styxie.Initializers.Welcome = {
  initialize: (params) => {
    const currentUserEmail = Cookies.get(params.lvUserEmailKey)
    if (currentUserEmail && currentUserEmail.lenght > 0) return

    const lvSourceParams = Cookies.get(params.lvTrafficSourceKey)
    if (!lvSourceParams) {
      const { origin, href } = window.location
      Cookies.set(params.lvTrafficSourceKey, { origin, href }, { expires: 1 })
    }
  }
}
