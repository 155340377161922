import VueCategories from '../vue_widgets/gmb_categories'

Styxie.Initializers.GmbCategories = {
  index: ({ countries }) => {
    window.addEventListener('load', () => {
      new Vue({ // eslint-disable-line no-new
        el: document.getElementById('vue-categories'),
        render: (h) => h(VueCategories, { props: { countries } })
      })
    })
  }
}
